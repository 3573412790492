import { Image } from "~/components/Image";

interface StaffGroupProps {
  employees: StaffGroupEmployee[];
  title: string;
  variant: "grid" | "list";
}

interface StaffGroupEmployee {
  name: string;
  title: string;
  email: string;
}

export function StaffGroup({ employees, title, variant }: StaffGroupProps) {
  return (
    <div className="block block__staff-group px-6">
      {variant === "grid" ? (
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-semibold">{title}</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {employees.map((employee) => (
              <EmployeeCard key={employee.email} employee={employee} />
            ))}
          </div>
        </div>
      ) : (
        <div>
          <h2 className="text-4xl font-semibold">{title}</h2>
          <div className="divide-y divide-primary">
            {employees.map((employee) => (
              <EmployeeCard key={employee.email} employee={employee} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function EmployeeCard({ employee }: { employee: StaffGroupEmployee }) {
  return (
    <div className=" p-4 flex gap-6 items-center">
      <div className="flex-shrink-0 size-32">
        <div className="bg-primary/15 aspect-[1/1] rounded-full overflow-clip">
          {employee.image.url && (
            <Image
              src={employee.image.url}
              alt={employee.name}
              width={300}
              className="object-cover h-full w-full object-top"
            />
          )}
        </div>
      </div>
      <div>
        <h3 className="font-semibold">{employee.name}</h3>
        <p className="text-sm text-balance">{employee.title}</p>
        <div className="pt-2 leading-none">
          <p className="text-sm text-balance">{employee.phone}</p>
          <a className="text-sm" href={`mailto:${employee.email}`}>
            {employee.email}
          </a>
        </div>
      </div>
    </div>
  );
}
