import { useLoaderData } from "@remix-run/react";
import { ActivityBrowser } from "~/components/activities/activity-browser";

export function ActivityBrowserBlock({ category, activities, activityCategories, ...props }) {
  const data: any = useLoaderData();

  return (
    <div className="block block__activity-browser">
      <div className="max-w-5xl mx-auto">
        <ActivityBrowser
          activities={activities}
          categories={activityCategories}
          filters={{
            search: data.filters.search,
            category: [category],
          }}
          showFilters={false}
          showCategorySearch={true}
        />
      </div>
    </div>
  );
}
