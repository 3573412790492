import { env } from "~/lib/env";
// import { storage } from "~/lib/storage";
import { HTTP_ERRORS } from "../http-errors";

export interface Page {
  id: number;
  date: string;
  date_gmt: string;
  guid: {
    rendered: string;
  };
  modified: string;
  modified_gmt: string;
  slug: string;
  status: string;
  type: string;
  link: string;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
    protected: boolean;
  };
  excerpt: {
    rendered: string;
    protected: boolean;
  };
  author: number;
  featured_media: number;
  parent: number;
  menu_order: number;
  comment_status: string;
  ping_status: string;
  template: string;
  meta: {
    _acf_changed: boolean;
    footnotes: string;
  };
  acf: {
    page_blocks: PageBlock[];
  };
  _links: PageLinks;
}

interface PageBlock {
  acf_fc_layout: string;
  editor: string;
}

interface PageLinks {
  self: Link[];
  collection: Link[];
  about: Link[];
  author: LinkWithEmbeddable[];
  replies: LinkWithEmbeddable[];
  "version-history": LinkWithCount[];
  "predecessor-version": LinkWithId[];
  "wp:attachment": Link[];
  curies: Cury[];
}

interface Link {
  href: string;
}

interface LinkWithEmbeddable extends Link {
  embeddable: boolean;
}

interface LinkWithCount extends Link {
  count: number;
}

interface LinkWithId extends Link {
  id: number;
}

interface Cury {
  name: string;
  href: string;
  templated: boolean;
}

class APIClient {
  private cmsUrl: string;
  private apiUrl: string;
  private baseUrl: string;

  constructor(type = "cms") {
    this.cmsUrl = env("CMS_URL");
    this.apiUrl = env("BACKEND_API_URL");
    this.baseUrl = type === "cms" ? this.cmsUrl : this.apiUrl;
  }

  async get(path: string, params: object = {}, options?: RequestInit): Promise<any> {
    try {
      const url = new URL(path, this.baseUrl);

      const defaultParams = {
        acf_format: "standard",
      };

      params = { ...defaultParams, ...params };

      // remove empty params
      Object.keys(params).forEach((key) => {
        if (!params[key]) {
          delete params[key];
        }
      });

      Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

      const cacheKey = url.toString().replace(/[^a-z0-9]/gi, "_");

      // if (await storage.hasItem(cacheKey)) {
      //   console.log("has " + cacheKey);
      //   return storage.getItem(cacheKey);
      // }
      // console.log("no " + cacheKey);

      const response = await fetch(url, options);
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error(HTTP_ERRORS[404]);
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // storage.setItem(cacheKey, data);
      return data;
    } catch (error) {
      // console.error("Error:", error);
      throw error;
    }
  }

  async getRaw(path: string, params: object = {}, options?: RequestInit): Promise<any> {
    try {
      const url = new URL(path, this.cmsUrl);

      const defaultParams = {
        acf_format: "standard",
      };
      params = { ...defaultParams, ...params };
      Object.keys(params).forEach((key) => {
        // if empty don't add to search params
        if (params[key]) {
          url.searchParams.append(key, params[key]);
        }
      });

      const response = await fetch(url, options);
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error(HTTP_ERRORS[404]);
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response;
    } catch (error) {
      // console.error("Error:", error);
      throw error;
    }
  }
}

export function cmsClient() {
  return new APIClient("cms");
}

export function apiClient() {
  return new APIClient("api");
}

export async function getNavMenus() {
  return await cmsClient().get("/wp-json/fppd/v1/menus");
}

export async function getPage(path: string): Promise<Page> {
  return await cmsClient().get(path);
}

export async function getPageBySlug(slug: string): Promise<Page> {
  if (!slug.startsWith("/")) {
    slug = `/${slug}`;
  }

  return await cmsClient().get(slug);
}

export async function getParkBySlug(slug: string) {
  return await cmsClient().get(`/wp-json/fppd/v1/parks/${slug}`);
}

export function getPageBlocks(page: Page) {
  return page?.acf?.page_blocks ?? [];
}

export function getPageTemplate(page: Page) {
  return page.template?.replace(".php", "").replace("templates/", "");
}

export async function getOptions() {
  return await cmsClient().get("/wp-json/fppd/v1/options");
}

export async function getPosts(params: {} = {}) {
  params.per_page = 6;
  const res = await cmsClient().getRaw("/wp-json/wp/v2/posts", params);
  // get pagination info from headers
  const headers = res.headers;
  const currentPage = parseInt(params?.page) || 1;
  const totalPages = parseInt(headers.get("x-wp-totalpages"));
  const nextPage = currentPage < totalPages ? currentPage + 1 : null;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const pagination = {
    total: headers.get("x-wp-total"),
    totalPages: totalPages,
    nextPage,
    prevPage,
    currentPage,
  };
  const data = await res.json();
  return { data, pagination };
}

export async function getIceArenaEvents(params: {} = {}) {
  //
}

export async function getEvents(params: {} = {}) {
  return await cmsClient().get("/wp-json/fppd/v1/events", params);
}
export async function getCalendarEvents(params: {} = {}) {
  return await cmsClient().get("/wp-json/fppd/v1/calendar-events", params);
}

export async function getCmsSearchResults(params: {}) {
  params = {
    ...params,
    // these limit the search to specific post types
    subtype: ["post", "page", "park", "event", "facility", "board-docs", "public-document", "rentable"],
  };
  return await cmsClient().get("/wp-json/wp/v2/search", params);
}

export async function getSearchResults(params: {}) {
  console.log(params);
  return await apiClient().get("/api/search", params);
}

export async function getExportPaths() {
  return await apiClient().get("/api/export-paths");
}
