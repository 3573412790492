import { Container } from "~/components/container";
import { cn } from "~/lib/utils";

export interface TextEditorProps {
  editor: string;
  className?: string;
}

export function TextEditor({ editor, className = "" }: TextEditorProps) {
  return (
    <div className={cn("block block__text-editor", className)}>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: editor }} />
      </Container>
    </div>
  );
}
