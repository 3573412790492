import { Link } from "@remix-run/react";
import { Heading } from "~/components/heading";
import { PostCard } from "~/components/post-card";
import { Button } from "~/components/ui/button";

export function LatestNewsAndAnnouncements({ posts }) {
  return (
    <section className="block block__latest-news-and-announcements bg-foreground text-background py-10 px-10">
      <Heading asChild>
        <h2>Latest News & Announcements</h2>
      </Heading>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-12 sm:gap-3 mt-6">
        {posts.map((post) => {
          return <PostCard key={post.id} post={post} />;
        })}
      </div>
      <div className="mt-6">
        <Button asChild className="w-full lg:w-auto" size="lg" variant="secondary">
          <Link to="/news-announcements">View All News and Announcements</Link>
        </Button>
      </div>
    </section>
  );
}
