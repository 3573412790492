import { Button } from "~/components/ui/button";
import { DownloadCloud } from "lucide-react";

export function PublicDocumentList({ title, documents }) {
  return (
    <section className="block block__public-document-list mt-12">
      <h2 className="font-semibold text-3xl">{title}</h2>
      <ul>
        {documents.map((document, index) => {
          return (
            <li key={index} className="mt-2">
              <Button asChild variant="outline">
                <a href={document.file.url} target="_blank" rel="noopener" className="py-1 flex gap-2">
                  {document.title} <DownloadCloud className="size-4" />
                </a>
              </Button>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
