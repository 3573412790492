import { Heading } from "~/components/heading";
import { Brochure } from "~/components/brochure";
import { EventCard } from "~/components/calendar/event-card";
import { Button } from "~/components/ui/button";

export function UpcomingEventsAndBrochure({ brochure, events }) {
  return (
    <section className="grid md:grid-cols-2">
      <section className="bg-[#C7DA41]/15 p-10">
        <Heading asChild>
          <h2>
            Upcoming <br className="hidden md:block" /> Events
          </h2>
        </Heading>
        <div className="grid md:grid-cols-3 gap-3 mt-6">
          {events.map((event) => {
            return <EventCard key={event.id} event={event} />;
          })}
        </div>
        <div className="mt-6">
          <Button asChild className="w-full lg:w-auto" size="lg" variant="secondary">
            <a href="/events">View All Events</a>
          </Button>
        </div>
      </section>
      <section className="bg-primary/15 p-10 grid md:grid-cols-2 lg:gap-10 text-center md:text-left">
        <div>
          <Heading asChild>
            <h2>
              Seasonal <br className="hidden md:block" /> Brochure
            </h2>
          </Heading>
          <div className="mt-6">
            <Button asChild size="lg" variant="secondary" className="hidden lg:inline-flex">
              <a href="/programs">Register Online</a>
            </Button>
            <Button asChild size="lg" variant="secondary" className="flex lg:hidden">
              <a href="/programs">Register Online</a>
            </Button>
            <div className="mt-6 md:mt-12">
              <h3 className="font-semibold">Registration Dates</h3>
              <p>Resident: {brochure.registration_date_resident}</p>
              <p>Non-Resident: {brochure.registration_date_nonresident}</p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <Brochure images={brochure.images} url={brochure.url} />
        </div>
      </section>
    </section>
  );
}
