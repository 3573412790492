import { MetaFunction } from "@remix-run/react";
import { isEmpty } from "./utils";

export const pageMeta: MetaFunction = ({ data }) => {
  const page = data?.page;

  const defaultDescription =
    "Discover the best parks, recreational activities, and community events at the Park District of Franklin Park. Enjoy family-friendly facilities, sports programs, and more. Join us for outdoor fun and wellness in a vibrant, welcoming environment.";

  const title = page?.title?.rendered ?? "404 Page Not Found";
  const description = isEmpty(page?.excerpt?.rendered) ? defaultDescription : page.excerpt.rendered;

  return [
    {
      title: `${title} | Park District of Franklin Park`,
    },
    {
      name: "description",
      content: description,
    },
  ];
};
