import { Container } from "~/components/container";
import { Button } from "~/components/ui/button";
import { DownloadCloud } from "lucide-react";

interface BoardDoc {
  id: number;
  title: string;
  type: string;
  date: string;
  agenda:
    | {
        id: number;
        title: string;
        filename: string;
        filesize: number;
        url: string;
      }
    | false;
  minutes:
    | {
        id: number;
        title: string;
        filename: string;
        filesize: number;
        url: string;
      }
    | false;
}

interface LatestAgendasAndMinutesProps {
  board_docs: BoardDoc[];
}

export function AgendaAndMinutesArchive(props: LatestAgendasAndMinutesProps) {
  return (
    <section className="block block__latest-agendas-and-minutes mt-12 px-4">
      <Container>
        <h2 className="font-semibold text-3xl">Agendas and Minutes</h2>
        <p>Stay up to date with the latest agendas and minutes from our meetings.</p>
        {props.board_docs.length > 0 ? (
          <ul className="divide-y">
            {props.board_docs.map((doc) => (
              <li key={doc.id} className="py-6">
                <h3 className="font-semibold">{doc.title}</h3>
                <div className="flex gap-3 pt-3">
                  {doc.agenda && (
                    <Button variant="outline" asChild>
                      <a className="flex items-center gap-2" href={doc.agenda.url} target="_blank" download>
                        Agenda <DownloadCloud className="size-4" />
                      </a>
                    </Button>
                  )}
                  {doc.minutes && (
                    <Button variant="outline" asChild>
                      <a className="flex items-center gap-2" href={doc.minutes.url} target="_blank" download>
                        Minutes <DownloadCloud className="size-4" />
                      </a>
                    </Button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No board docs found.</p>
        )}
      </Container>
    </section>
  );
}
