import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

export default function FaqGroup({ title, faq }) {
  return (
    <div className="block__faq-group">
      <h2 className="text-2xl font-semibold mb-4">{title}</h2>
      <Accordion type="multiple">
        {faq.map((faq, index) => {
          return (
            <AccordionItem key={index} value={faq.question}>
              <AccordionTrigger className="text-left">{faq.question}</AccordionTrigger>
              <AccordionContent>
                <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
}
