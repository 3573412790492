import { Image } from "./Image";

export function Brochure({ images, url }) {
  const mainImage = images[0]?.image;
  return (
    <div className="bg-theme-green-2">
      <a href={url} target="_blank" rel="noreferrer" className="block" title="Seasonal Brochure">
        <Image src={mainImage.url} alt={mainImage.alt} className="w-full h-auto" />
        <span className="block text-white bg-primary font-semibold uppercase text-xs p-1 text-center">
          View Brochure
        </span>
      </a>
    </div>
  );
}
