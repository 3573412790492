import {
  APIProvider,
  Map,
  Marker,
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
  useMap,
} from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { Image } from "~/components/Image";

export function InteractiveMap({ parks, googleMapsApiKey }) {
  const communityCenter = { lat: 41.936091, lng: -87.8661 };
  const [center, setCenter] = useState(communityCenter);
  const defaultZoom = 15;

  const [selectedPark, setSelectedPark] = useState(null);

  return (
    <APIProvider apiKey={googleMapsApiKey}>
      <div className="w-svw h-[calc(100svh-90px)] md:h-[calc(100svh-150px)]">
        <div className="flex flex-col-reverse md:flex-row h-full">
          <div className="md:w-1/4 bg-theme-green-2 text-white p-4 overflow-y-scroll">
            <h2 className="text-white font-semibold tracking-wider text-2xl uppercase py-3">Parks</h2>
            <ul className="divide-y divide-white/30 overflow-y-scroll">
              {parks.map((park) => (
                <ParkListing key={park.id} park={park} setSelectedPark={setSelectedPark} />
              ))}
            </ul>
          </div>
          <div className="md:w-3/4 aspect-[16/13] flex-shrink-0">
            <Map mapId="efa46302d3d4caed" defaultCenter={center} defaultZoom={defaultZoom}>
              {parks.map((park) => (
                <ParkMarker key={park.id} park={park} selectedPark={selectedPark} />
              ))}
            </Map>
          </div>
        </div>
      </div>
    </APIProvider>
  );
}

function ParkListing({ park, setSelectedPark }) {
  const addressString = `${park.address.street} ${park.address.city} IL ${park.address.zip}`;
  const directionsUrl = `https://www.google.com/maps/place/${addressString}`;
  const map = useMap();

  return (
    <li
      key={park.id}
      className="py-6 cursor-pointer"
      onClick={() => {
        map?.panTo({ lat: park.latitude, lng: park.longitude });
        setSelectedPark(park);
      }}
    >
      <div className="font-semibold uppercase tracking-wider">{park.title}</div>
      <div>{addressString}</div>
    </li>
  );
}

function ParkMarker({ park, selectedPark }) {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const addressString = `${park.address.street} ${park.address.city} IL ${park.address.zip}`;
  const imageUrl = park?.hero?.image?.url;

  useEffect(() => {
    if (selectedPark?.id === park.id) {
      setInfowindowOpen(true);
    } else {
      setInfowindowOpen(false);
    }
  }, [selectedPark]);

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(true)}
        position={{ lat: park.latitude, lng: park.longitude }}
        title={park.title}
      />
      {infowindowOpen && (
        <InfoWindow anchor={marker} maxWidth={200} onCloseClick={() => setInfowindowOpen(false)} headerDisabled={true}>
          {imageUrl && <Image src={imageUrl} alt={park.title} className="w-full h-32 object-cover mb-2" />}
          <div className="font-semibold">{park.title}</div>
          <div>{addressString}</div>
          <div className="mt-1">
            <a href={park.url} className="text-primary underline">
              More info
            </a>
          </div>
        </InfoWindow>
      )}
    </>
  );
}
