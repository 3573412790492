import { Container } from "~/components/container";
import { Button } from "~/components/ui/button";
import { DownloadCloud } from "lucide-react";

export function ProgramDocuments({ title, programs }) {
  const getDocumentTitle = (document) => {
    const displayName = document.display_name;

    if (displayName) {
      return displayName;
    }

    return document.file.title;
  };

  return (
    <div className="block__program-documents">
      <Container>
        <h2 className="font-semibold text-3xl">{title}</h2>
        <div className="divide-y">
          {programs.map((program, index) => {
            return (
              <div key={index} className=" mt-2 py-4">
                <h3 className="text-lg font-semibold">{program.program_name}</h3>
                <div className="flex gap-2 pt-2 flex-wrap">
                  {program.program_documents.map((document, index) => {
                    return (
                      <Button variant="outline" asChild key={`doc_${index}`}>
                        <a className="flex items-center gap-2" href={document.file.url} target="_blank" download>
                          {getDocumentTitle(document)} <DownloadCloud className="size-4" />
                        </a>
                      </Button>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
