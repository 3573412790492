import { TextEditor } from "./text-editor";
import { StaffGroup } from "./staff-group";
import { Slider } from "./slider";
import { QuickLinks } from "./quick-links";
import { LatestNewsAndAnnouncements } from "./latest-news-and-announcements";
import { UpcomingEventsAndBrochure } from "./upcoming-events-and-brochure";
import { HeroText } from "./hero-text";
import { LatestAgendasAndMinutes } from "./latest-agendas-and-minutes";
import { PublicDocumentList } from "./public-document-list";
import { ActivityBrowserBlock } from "./activity-browser";
import { InteractiveMap } from "./interactive-map";
import { ProgramDocuments } from "./program-documents";
import FaqGroup from "./faq-group";
import { AgendaAndMinutesArchive } from "./agenda-and-minutes-archive";

export function BlockFactory({ blocks }) {
  const blocksMap = {
    text_editor: TextEditor,
    staff_group: StaffGroup,
    slider: Slider,
    quick_links: QuickLinks,
    upcoming_events_and_brochure: UpcomingEventsAndBrochure,
    latest_news_and_announcements: LatestNewsAndAnnouncements,
    hero_text: HeroText,
    latest_agendas_and_minutes: LatestAgendasAndMinutes,
    public_document_list: PublicDocumentList,
    activity_browser: ActivityBrowserBlock,
    interactive_map: InteractiveMap,
    program_documents: ProgramDocuments,
    faq_group: FaqGroup,
    agenda_and_minutes_archive: AgendaAndMinutesArchive,
  };

  return (
    <div className="blocks">
      {blocks.map((block, index) => {
        const isFirst = index === 0;
        const isLast = index === blocks.length - 1;
        // Weird if a layout was added to a page but then deleted
        if (block === null) return null;

        const BlockComponent = blocksMap[block.acf_fc_layout];

        if (!BlockComponent) {
          console.error(`Block component not found for ${block.acf_fc_layout}`);
          return null;
        }

        block.className = block.className || "";

        if (block.acf_fc_layout === "text_editor" && isFirst) {
          block.className += " mt-12";
        }

        if (block.acf_fc_layout === "text_editor" && isLast) {
          block.className += " mb-12";
        }

        return <BlockComponent key={index} {...block} />;
      })}
    </div>
  );
}
